@font-face {
	font-family: 'HumanistSlabserif712 BT Roman';
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.eot');
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.eot?#iefix')
			format('embedded-opentype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.woff2')
			format('woff2'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.woff')
			format('woff'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.ttf')
			format('truetype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Roman.svg#HumanistSlabserif712BT-Roman')
			format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HumanistSlabserif712 BT Black';
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.eot');
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.eot?#iefix')
			format('embedded-opentype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.woff2')
			format('woff2'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.woff')
			format('woff'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.ttf')
			format('truetype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/HumanistSlabserif712BT-Black.svg#HumanistSlabserif712BT-Black')
			format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'EgyptienneURWLig';
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.eot');
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.eot?#iefix')
			format('embedded-opentype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.woff2') format('woff2'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.woff') format('woff'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.ttf') format('truetype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/EgyptienneURW-Lig.svg#EgyptienneURW-Lig')
			format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Futura Std Bold';
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.eot');
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.woff2') format('woff2'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.woff') format('woff'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.ttf') format('truetype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Bold.svg#FuturaStd-Bold')
			format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Futura Std Book';
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.eot');
	src: url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.eot?#iefix')
			format('embedded-opentype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.woff2') format('woff2'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.woff') format('woff'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.ttf') format('truetype'),
		url('https://strykee-mym-2020.s3-us-west-2.amazonaws.com/Stryker/FuturaStd-Book.svg#FuturaStd-Book')
			format('svg');
	font-weight: normal;
	font-style: normal;
}

.futurastdbold {
	font-family: "Futura Std Bold" !important;
}

.futurastdbook {
	font-family: "Futura Std Book" !important;
}

.humanistbtroman {
	font-family: "HumanistSlabserif712 BT Roman" !important;
}

.humanistbtblack {
	font-family: "HumanistSlabserif712 BT Black" !important;
}

.egyptiennelig {
	font-family: "EgyptienneURWLig" !important;
}

body {
	font-family: "FuturaStd", serif;
	color: #000000 !important;
	background: #f1f1f1 !important;
}

input {
	background-color: white;
}

textarea {
	background-color: white;
}
#root {
	max-width: 900px;
	margin: auto;
	min-height: 100vh;
	background: white;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.container {
	width: 100% !important;
}

@media (min-width: 768px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 100% !important;
	}
}

@media (min-width: 576px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 100% !important;
	}
}

input {
	outline: none !important;
}

textarea {
	outline: none !important;
	resize: auto !important;
	overflow: hidden !important;
}

button {
	outline: none !important;
	box-shadow: none !important;
}

p {
	font-family: 'HumanistSlabserif712BT';
	font-size: .8rem;
	font-weight: 300;
	line-height: 1.2;
}

.section {
	margin-bottom: 30px;
}

.section-title {
	font-weight: 100;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 1.8rem;
}

.icon {
	width: 16px;
}

::placeholder {
	font-size: 0.75rem;
	opacity: 0.5 !important;
}

a {
	color: inherit !important;
	text-decoration: none !important;
	transition: .3s ease-in-out;
}

a:active,
a:visited {
	color: #000;
	text-decoration: none !important;
	transition: .3s ease-in-out;
}

a:hover {
	color: #000 !important;
}

.pad-lf-neg10 {
	margin-left: -15px;
}

.mar-tp-neg12 {
	margin-top: -12px;
}

.tab-title {
	font-family: "Futura Std Bold", sans-serif;
	color: #b1b1b1;
	font-size: 1.1rem;
}

.tab-title-main {
	position: relative;
	z-index: 0;
	padding: 0 15px 15px 15px;
	padding-top: 90px;
	border-radius: 0 0 0 20px;
	box-shadow: 0 9px 9px #e2e2e2;
	background-size: contain;
	background-color: #fff;
}

.tab-title-survey {
	padding: 0 0 15px 0;
	padding-top: 90px;
	border-radius: 0 0 0 20px;
	box-shadow: 0 9px 9px #e2e2e2;
	background-size: contain;
	background-color: #fff;
}

.mr-3half {
	margin-right: 35px;
}

.pt-15 {
	padding-top: 15px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-35 {
	padding-bottom: 35px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-adj {
	margin-top: -15px;
}

.pl-adj {
	padding-left: -15px;
}

.disabled {
	pointer-events: none;
	cursor: default;
	opacity: .4;
}

.uppercased {
	text-transform: uppercase;
}

.hr-bk-expand-ml-mr-15 {
	border-bottom: 1px solid #888;
	margin-left: -15px;
	margin-right: -15px;
}

/*--------- NAV --------------*/

.tab-navbar {
	background-color: #fff;
	background-size: contain;
	border-bottom: 2px solid #f5b840;
	border-radius: 0 0 0 20px;
	box-shadow: 0px 8px 8px #e2e2e2;
	position: fixed;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	z-index: 25;
	width: 100%;
	top: 0;
	max-width: 900px;
}

.menubar i {
	height: 4vh;
	font-size: 1.5rem;
	margin-left: 15px;
	padding-top: 20px;
	padding-bottom: 30px;
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

.navbar {
	width: 100%;
	justify-content: center !important;
}

.navbarfile {
	font-family: "Futura", serif;
	font-weight: 100;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #f5b840;
	padding: 0 20px;
	border-radius: 0 0 0 20px;
	box-shadow: 0px 8px 8px #dcd9d9;
}

.navbarfilename {
	font-weight: 900;
	font-size: .85rem;
	margin-top: 20px;
}

.dots {
	padding-left: 30px;
	align-items: center;
	font-size: 1.5rem;
	color: #212121;
	right: 15px;
	cursor: pointer;
	z-index: 25;
	margin-top: 100px;
}

.back {
	font-family: "Futura", serif;
	font-weight: 100;
	font-size: 0.8rem;
	white-space: nowrap;
	position: absolute;
	color: #555;
	cursor: pointer;
	z-index: 200;
}

.back i {
	color: #f5b640;
	margin-right: 5px;
}

.navbar img {
	height: 40px;
	margin: auto;
	padding-bottom: 5px;
}

.navbar.logo-stryker img {
	height: 4vh;
	margin: auto;
	margin-top: -38px;
	padding-bottom: 5px;
}

.sykyellow {
	color: #ffb500;
}

.yellow {
	color: #f5b640;
}

.line-expand {
	border-top: 1px solid #6d6d6d;
}

.line-expand-ltGrey {
	border-top: 1px solid #acacac;
}

.line-blk {
	border-top: 1px solid #6d6d6d;
	margin: 15px -15px 15px -15px;
}

.line-blk-original {
	border-top: 1px solid #6d6d6d;
	margin: 15px 0 15px 0;
}

.line-blk-expand {
	border-top: 1px solid #6d6d6d;
	margin: 10px 0 15px 0;
}

.line-grey-expand {
	border-top: 1px solid #acacac;
	margin: 10px -30px 15px -30px;
}

.line-blk-shrink {
	border-top: 1px solid #6d6d6d;
	margin: 10px -15px 15px -15px;
}

.line-blk-shrink2 {
	border-top: 1px solid #6d6d6d;
	margin: 10px -15px -1px -15px;
	z-index: 5;
}

.line-grey {
	border-top: 1px solid #cecccc;
	margin: 0px -15px 3px -15px;
}

.line-grey-original {
	border-top: 1px solid #cecccc;
	margin: 0 0 3px 0;
}

.table > tbody > tr:first-child > td {
	border: none;
}

/*----------HAMBURGER----------------*/

a.favorite:hover,
a.download:hover,
a.cancel:hover {
	color: #000 !important;
}

a.download:hover {
	color: #9d9d9d !important;
	background-color: white !important;
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .95;
	}
}

@keyframes fadeInSome {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .95;
	}
}

@keyframes fadeInLess {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .8;
	}
}

@keyframes slideIn {
	0% {
		transform: translateY(-2%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes shrink {
	0% {
		width: 95%;
	}
	100% {
		width: 90%;
	}
}

/*--------- DASHBOARD --------------*/

.page {
	background: #ffffff;
	max-width: 900px;
	margin: auto;
}

.dashboard-bar {
	padding-left: 15px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 1rem;
	justify-content: space-between;
	padding-bottom: 1rem;
	margin: 0 -15px !important;
	padding-right: 15px;
	background: white;
	border-radius: 0 0 0px 20px;
}

.dashboard-bar img {
	max-height: 3rem;
}

.dashboard-top {
	position: relative;
	z-index: 5;
	background-image: linear-gradient(to right, #ffc700, #ffa900, #a46a24);
	border-radius: 0 0 0 20px;
	box-shadow: 0px 8px 8px #dcd9d9;
}

.dashboard-stats {
	padding: 0 10px;
	overflow: auto;
	display: flex;
}

.dashboard-stats .stats {
	text-align: left;
	font-size: 0.65rem;
	letter-spacing: .6px;
}

.dashboard-stats .number {
	font-size: 2.7rem;
	line-height: 2.8rem;
	color: white;
	font-family: "Futura Std Bold", serif;
}

.dashboard-stats .number.dkgrey {
	color: #4b4e51;
}

.stats2 label,
.trial-statistics__label {
	font-family: "Futura Std Book", serif;
	font-size: .5rem;
	margin: 0;
}

.network-connection {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.on-off {
	margin-right: 10px;
	font-size: 0.8rem;
}

.initial {
	width: 100%;
	text-align: center;
	color: white;
	font-weight: 800;
	background-color: #454546;
	padding: 7px;
	border-radius: 20px;
}

.d-board-sect-title {
	font-family: "EgyptienneURWLig", sans-serif;
	font-weight: 100;
	font-size: 1.4rem;
	margin-bottom: 5px;
}

.d-board-item {
	font-family: "Futura", serif;
	font-weight: 100;
	letter-spacing: .3px;
	min-width: 31.5%;
	font-size: 0.6rem;
	text-align: center;
}

.d-board-item a {
	color: #000;
}

.d-board-thumb {
	width: 100%;
	border: 1px solid #7f8082;
	border-radius: 4px;
	margin-bottom: 5px;
}

.d-board-thumb.grey {
	background-color: #54575a;
}

.d-board-item img {
	margin: auto;
}

.product-edu-wrap.container {
	padding-right: 0;
	width: 100vw;
}

.product-edu-container {
	background: white;
	border-radius: 0 0 0 20px;
	padding: 20px 0 20px 10px;
	box-shadow: 0 0 14px #cecece;
}

.product-edu-container.col-12 {
	padding-left: 10px;
	padding-right: 10px;
	overflow: hidden;
}

.product-edu {
	display: flex;
	align-items: top;
	margin-bottom: 10px;
}

.product-education__image-container {
	border: 1px solid #7f8082;
	border-radius: 4px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-education__image {
	max-width: 80%;
	max-height: 80%;
	width: auto;
	border: none;
}

.prod-tabs-wrapper {
	width: 100% !important;
	overflow-x: scroll;
}

.product_education_column {
	min-width: 31.5%;
	margin-right: 5px;
}

.prod-tabs .d-board-item {
	margin-bottom: 10px;
}

.dashboard-item-name {
	margin-top: 5px;
}

.prod-tabs {
	width: 100%;
}

.indicatorsContainer {
	width: 20px;
	margin: auto;
}

.indicators {
	flex-direction: row;
	display: flex;
	justify-content: space-between;
}

.indicator {
	width: 6px;
	height: 6px;
	border: 1px solid #9d9d9d;
	border-radius: 3px;
}

.indicator.selected {
	background: #9d9d9d;
}

/*-------------TRIALS --------------*/

.icon-cases img {
	width: 70px;
	opacity: .6;
	margin-right: 30px;
}

.casestable-stats.adj-m {
	padding: 15px 10px 15px 10px;
	font-family: "Futura", serif;
	font-weight: 100;
}

.casestable-stats .number {
	font-size: 2.7rem;
	line-height: 2.8rem;
	font-weight: 800;
	color: #4b4e51;
	margin-left: 15px;
}

.cases-stats {
	text-align: left;
	font-size: 8px;
	padding-left: 15px;
	letter-spacing: .6px;
	width: 33.33%;
}

.table.cases-table {
	margin-top: 15px;
	margin-bottom: 15px;
}

.cases-table tr td {
	padding: 0;
}

td.icon-cases {
	width: 15%;
}

.case-no {
	font-size: 1.2rem;
	color: #4b4e51;
}

.case-name {
	font-weight: 100;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 1rem;
}

.case-info {
	padding-top: 7px;
}

.percentage {
	font-size: 2.2rem;
	float: right;
}

h2.case-info {
	padding-top: 17px;
	font-weight: 600;
	color: #4b4e51;
}

.p-sign {
	font-size: 1rem;
}

.cases-table tr {
	border-bottom: 1px solid #dee2e6;
}

.cases-table {
	border-bottom: 1px solid #dee2e6;
	border-top: 1px solid black;
	margin-top: 15px;
}

.cases-table td {
	border-top: none;
}

.cases-stats.adj-m {
	margin-bottom: 30px;
	padding-left: 15px;
	padding-right: 16px;
	border-bottom: 1px solid #dee2e6;
}

td.file-details .case-info {
	text-align: left;
}

.intel,
.talk,
.trial,
.camera,
.pneumo,
.spyphi,
.advImaging,
.example,
.people,
.cases,
.checklist,
.trialsummary,
.notes,
.survey,
.collateral,
.engagement,
.relatedtrials,
.compcollateral,
.bariatric,
.colorectal,
.cv,
.ent,
.general,
.gi,
.onc,
.ortho,
.pediatric,
.skull,
.uro,
.breast,
.trauma,
.arthrex,
.conmed,
.depuy,
.olympus,
.sn,
.steris,
.storz,
.gyn {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 45%;
	width: 100%;
	height: 100px;
}

.intel {
	background-image: url("/images/tab-intel.png");
}

.talk {
	background-image: url("/images/tab-talk.png");
}

.trial {
	background-image: url("/images/tab-trial.png");
}

.camera {
	background-image: url("/images/tab-1688cam.png");
}

.pneumo {
	background-image: url("/images/tab-pneumo.png");
}

.spyphi {
	background-image: url("/images/tab-spyphi.png");
}

.advImaging {
	background-image: url("/images/tab-advImaging.png");
}

.example {
	background-image: url("/images/tab-example.png");
}

.people {
	background-image: url("/images/tab-people.png");
}

.cases {
	background-image: url("/images/tab-cases.png");
}

.checklist {
	background-image: url("/images/tab-checklist.png");
}

.trialsummary {
	background-image: url("/images/tab-trialsummary.png");
}

.notes {
	background-image: url("/images/tab-trialsummary.png");
}

.survey {
	background-image: url("/images/tab-survey.png");
}

.collateral {
	background-image: url("/images/tab-survey.png");
}

.engagement {
	background-image: url("/images/tab-engagement.png");
}

.relatedtrials {
	background-image: url("/images/tab-relatedtrials.png");
}

.compcollateral {
	background-image: url("/images/tab-compcollateral.png");
}

.bariatric {
	background-image: url("/images/tab-bariatric.png");
}

.colorectal {
	background-image: url("/images/tab-colorectal.png");
}

.cv {
	background-image: url("/images/tab-cv.png");
}

.ent {
	background-image: url("/images/tab-ent.png");
}

.general {
	background-image: url("/images/tab-general.png");
}

.gi {
	background-image: url("/images/tab-gi.png");
	background-color: #54575a;
}

.gyn {
	background-image: url("/images/tab-gyn.png");
}

.onc {
	background-image: url("/images/tab-onc.png");
	background-color: #54575a;
}

.ortho {
	background-image: url("/images/tab-ortho.png");
}

.pediatric {
	background-image: url("/images/tab-pediatric.png");
}

.skull {
	background-image: url("/images/tab-skull.png");
}

.uro {
	background-image: url("/images/tab-uro.png");
}

.breast {
	background-image: url("/images/tab-breast.png");
}

.trauma {
	background-image: url("/images/tab-trauma.png");
}

.arthrex {
	background-image: url("/images/tab-arthrex.png");
}

.conmed {
	background-image: url("/images/tab-conmed.png");
	background-color: #54575a;
}

.depuy {
	background-image: url("/images/tab-depuy.png");
}

.olympus {
	background-image: url("/images/tab-olympus.png");
}

.sn {
	background-image: url("/images/tab-sn.png");
}

.steris {
	background-image: url("/images/tab-steris.png");
}

.storz {
	background-image: url("/images/tab-storz.png");
}

.table td.email-name {
	display: inline;
	float: left;
	padding-bottom: 0;
}

.last-sent {
	font-weight: 300;
	font-size: 0.65rem;
	letter-spacing: .4px;
	color: #555;
	float: left;
}

.icon-eye-email,
.icon-pen-email,
.icon-trash-email {
	float: right;
	padding: 8px;
	background-color: #f5b840;
	border-radius: 16px;
	margin: 0 5px;
	color: black;
	font-size: 12px;
	margin-top: -17px;
}

.adjust-mt-neg15 {
	margin-top: -15px;
}

/*----------- TRIAL: ORGANIZATION -------------*/

.case-back {
	text-transform: uppercase;
	font-size: .9rem;
}

.case-back i {
	color: #f5b640;
}

/*-----DASHBOARD TOOLKIT----*/

.tabs {
	width: 40%;
	height: auto;
	padding-top: 6px;
}

.tab-tabs {
	background: white;
	border-radius: 0 20px;
	padding: 20px 10px 2px 10px;
	box-shadow: 0 0 14px #cecece;
	background-size: contain;
}

.tab-section {
	padding: 15px;
}

.toolkit {
	display: flex;
	justify-content: space-between;
	align-items: top;
	margin-bottom: 15px;
}

.my-stats {
	width: 100%;
	text-align: left;
	font-size: 8px;
	letter-spacing: .6px;
	padding: 10px;
	float: left;
	border-bottom: 1px solid #cecccc;
}

/*------ COMPETITIVE INTEL ARCHIVE ---------*/

.nav-pagelink {
	padding: 10px 0 20px 0px;
	/* border-bottom: 1px solid grey; */
	background: white;
	z-index: 2;
	position: relative;
}

.nav-pagelink-no-line {
	padding: 5px 0 15px 0;
	background: white;
	padding-bottom: 0;
	z-index: 2;
	position: relative;
}

.nav-pagelink-talk {
	padding: 5px 0 15px 0px;
	margin-left: -15px;
	background: white;
	padding-bottom: 0;
	z-index: 2;
	position: relative;
}

.star {
	color: #ccc;
	margin: auto;
	font-size: 0.8rem;
	border-radius: 10px;
	border: 1px solid;
	padding: -10px 3px 3px 3px;
}

.sort-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sort-star {
	border-radius: 50%;
	width: 2.3vh;
	height: 2.3vh;
	display: flex;
	border: 1px solid #b1b1b1;
	justify-content: center;
}

.sort {
	margin: center;
	justify-content: flex-end;
	font-size: 0.8rem;
}

.intel-name {
	font-weight: 800;
	display: flex;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid #0000001a;
	justify-content: space-between;
}

a.intel-name:hover {
	text-decoration: none;
}

.first-child {
	color: #000;
}

.intel-name:first-child {
	padding-top: 0;
}

.file {
	font-weight: 800;
	font-size: 1rem;
	list-style: none;
	margin-bottom: 0;
	padding-left: 0px;
	color: black;
}

.intel-name img {
	width: 25px;
	height: 25px;
	border-radius: 4px;
}

.modified {
	font-weight: 300;
	font-size: 0.65rem;
	letter-spacing: .4px;
	margin: 0;
	color: #555;
}

.angle {
	width: 5.5px;
	float: right;
	margin-top: -12px;
	margin-right: -15px;
}

.table-sort {
	border-top: 1px solid #e4e4e4;
}

table.filelist {
	margin-bottom: -15px;
}

/*----------- pdfViewer --------*/

#viewer {
	width: 100%;
	height: 100%;
	font-family: "Futura", serif;
	font-weight: 100;
}

/*-------- FILE LIST ------------*/

.table {
	font-size: .8rem;
}

table td,
.table th {
	padding-left: 0rem !important;
	padding-right: 0rem !important;
}

.table thead th {
	vertical-align: bottom;
	border: 0 !important;
}

tr {
	min-height: 60px !important;
}

li {
	word-break: break-word;
}

thead {
}

td.file-img img {
	width: 25px;
	border-radius: 4px;
	padding-left: 0;
}

td.trial-status img {
	width: 6px;
	border-radius: 4px;
	padding-left: 0;
}

.table td.angle {
	padding-right: 0;
}

.file-details ul {
	color: black;
	font-family: "Futura", serif;
}

.sort-order {
	font-family: "Futura", serif;
	font-weight: 100;
	letter-spacing: .4px;
	font-weight: 400;
	font-size: .75rem;
	float: right;
	color: #555;
}

th.sort-order.cursor.adjust {
	line-height: 3;
}

ul,
il {
	list-style: none;
	padding-left: 0;
}

td.file-details {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: .9rem;
	list-style: none;
	margin-bottom: 0;
	padding-right: 15px !important;
	padding-left: 15px !important;
	color: black;
	display: flex;
	flex-direction: column;
}

.file-details ul {
	margin-bottom: 0;
	padding-right: 0;
}

.file-image .favorited {
	margin-left: 5px;
	margin-right: 0 !important;
	margin-top: -10px;
	width: 30px;
}

td.file-img {
	width: 40px;
}

.favorited {
	margin-top: -8px;
	z-index: 5 !important;
}

.favor {
	background-image: url("../images/star-yel.png");
	width: 30px;
	height: 10px;
	margin-right: 0;
	background-repeat: no-repeat;
	background-position: 20px 0px;
	z-index: 5;
}

.favor-o {
	width: 30px;
	height: 10px;
	margin-right: 0;
	z-index: 5;
}

.cursor {
	cursor: pointer;
}

/*----------VIEW FILE ------------*/

.view-file {
	font-family: "Futura", serif;
	font-weight: 100;
	margin-top: 50px;
	border: 1px solid #d3d3d3;
	background: #fff;
	text-align: center;
}

/*---------BUSINESS UNIT ------------*/

.section-title-business {
	font-weight: 100;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 1.8rem;
	margin-top: 90px;
}

.business {
	border-bottom: 1px solid #b7b7b7;
}

.biz-unit {
	font-size: 1.15rem;
	font-weight: 800;
	padding: 20px 0 0;
	margin-bottom: 5px;
}

.warning {
	color: #9c261e;
	font-size: .7rem;
	font-family: "Futura Std Book", serif;
}

.warning-bold {
	font-family: 'HumanistSlabserif712 BT Black', sans-serif;
	font-weight: 900;
	font-size: .9rem;
	line-height: 1.25;
}

.warning-normal {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: .9rem;
	line-height: 1.25;
}

.panel-low {
	margin-top: 30px;
}

.switch {
	width: 36px;
	height: 21px;
}

/*--------- TALKTRACKS --------------*/

.nav-archive-talk a {
	margin-right: 30px;
}

.nav-archive-talk a.active p {
	border-bottom: 1px solid #fba100;
	margin-bottom: 0;
	color: #55575a;
	transition: .5s ease-in-out;
}

a.not-selected {
	color: #b1b1b1;
}

.nav-archive-talk a.sub {
	margin-right: 10px;
}

.talk-tracks.container {
	margin-top: 25px;
}

h4.section-title-single {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: 1.7rem;
}

.title-tab-single {
	border-radius: 0 0 0 20px;
	background-color: #fff;
	box-shadow: 0px 8px 8px #dcd9d9;
}

.tab-text {
	background-color: #fff;
	border-radius: 4px 20px;
	margin: 35px 0px;
	padding: 13px 15px;
	box-shadow: 4px 10px 16px #ccc;
}

.text-list {
	font-family: 'HumanistSlabserif712BT';
	font-size: .9rem;
	font-weight: 300;
	line-height: 1.3;
	list-style-type: disc;
	list-style-position: outside;
	color: black;
	margin-bottom: 20px;
}

.text-list span {
	position: relative;
	left: -3px;
}

.download {
	justify-content: flex-end;
	color: #000;
	font-size: 1.3rem;
	float: right;
	padding-right: 10px;
}

.path-nested {
	width: 100%;
}

/*------------ PRODUCT EDU ---------------*/

.section-title-prod-edu {
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1.5rem;
	margin-top: 12px;
	text-align: center;
}

.section-title-sub {
	font-family: "Futura Std Book", sans-serif;
	font-size: .7rem;
	color: #54575a;
	text-transform: uppercase;
}

.title-prod-tab {
	width: 102px;
	padding: 0 18px;
	border: 1px solid #888;
	border-radius: 4px;
	margin: 7px 0 18px;
}

.title-tab-prod {
	text-align: center;
}

.star-no-circle {
	color: #ccc;
	margin: auto;
	font-size: 1rem;
	padding: 3px;
}

.angle2 {
	width: 6px;
	height: 14px;
	margin-top: 3px;
	float: right;
}

.talktracks-signle {
	color: #000;
	padding-top: 7px;
	padding-bottom: 7px;
}

table.talktracks-single {
	margin-bottom: -2px;
}

/*---------- TRIALS -------------*/

.tab-flush-rt {
	background-color: #fff;
	border-radius: 4px 0 0 20px;
	margin: 35px 0 35px 15px;
	padding: 15px;
	box-shadow: 4px 10px 16px #ccc;
	background-size: contain;
}

.icon-round img {
	width: 40px;
}

td.trial-status img {
	width: 7px;
	height: 7px;
	padding-right: 0;
}

td.trial-status {
	padding-right: 0;
}

.created {
	font-weight: 300;
	font-size: 0.65rem;
	letter-spacing: .4px;
	color: #b1b1b1;
}

img.plus {
	width: 25px;
	float: right;
	margin-right: 10px;
}

h4.section-title.trials {
	display: inline-block;
}

table td.trial-status {
	padding-right: .1rem;
}

h4.section-title-single-lg {
	font-family: "Futura Std Bold", serif;
	font-size: 2rem;
	line-height: 2.5rem;
}

.section-title-single-lg {
	font-family: "Futura Std Bold", serif;
	font-size: 2rem;
	line-height: 2.5rem;
}

.statusbub {
	font-family: "Futura Std Book", serif;
	font-weight: 100;
	font-size: .7rem;
	background-color: #ffb500;
	min-width: 100px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	border-radius: 23px;
}

.cuddle {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	margin-right: 3px;
	margin-bottom: 5px;
}

.addteammate {
	font-weight: 100;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 1.2rem;
	margin-top: -2px;
}

.addteammate.inactive {
	color: #dfdfdf;
}

.bar-vert.inactive {
	opacity: .1;
	height: 30px;
}

.tab-title2 {
	font-family: "EgyptienneURWLig", sans-serif;
	font-weight: 100;
	color: #000;
	font-size: 1.5rem;
	letter-spacing: 0;
}

.trials-progress {
	font-size: 1rem;
	width: 100%;
	margin-left: 15px;
}

.denominator {
	font-size: .9rem;
	color: #949494;
}

.stats2 {
	width: 33.33%;
}

.complete {
	color: #333;
	font-size: .6rem;
	font-weight: 100;
}

.dashboard-stats.adj-m {
	padding-left: 15px;
	padding-right: 16px;
}

.stats-survey {
	width: 50%;
	text-align: center;
	font-size: 0.5rem;
	letter-spacing: .6px;
	display: inline-block;
}

.stats-survey-alignLeft {
	width: 50%;
	text-align: center;
	font-size: 0.5rem;
	letter-spacing: .6px;
	display: inline-block;
}

.section-title-survey {
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1.4rem;
	text-align: center;
	margin: 10px 0 10px 0;
}

.stats-survey .number {
	color: #4b4e51;
	font-size: 2.7rem;
	line-height: 2.8rem;
	font-family: "Futura Std Bold", sans-serif;
}

.stats-survey-detail {
	width: 50%;
	text-align: left;
	font-size: 8px;
	letter-spacing: .6px;
	margin-bottom: 10px;
	margin-top: 10px;
	padding-left: 30px;
	padding-right: 5px;
	float: left;
}

.stats-survey-date {
	font-family: 'HumanistSlabserif712BT';
	font-weight: 100;
	font-size: .85rem;
	text-align: left;
}

.tab-bar {
	display: flex;
	flex-direction: row;
	margin: 10px 0;
	cursor: pointer;
}

.tab-bar-text {
	font-family: "Futura Std Bold", sans-serif;
	font-size: 1rem;
	border: 1px solid #54575a;
	border-radius: 0 4px 4px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 8px;
	width: 100%;
}

.tab-bar-text2 {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: 1rem;
	border: 1px solid #cecccc;
	border-radius: 0 4px 4px 0;
	padding: 8px 8px;
	width: 100%;
}

.square-grey {
	background-color: #54575a;
	color: #fff;
	padding: 12px;
	border: 1px solid #54575a;
	border-radius: 4px 0 0 4px;
}

.square-red {
	background-color: #c60000;
	color: white;
	padding: 12px;
	border-radius: 4px 0 0 4px;
	border: 1px solid #54575a;
	border-right: 0;
	border-radius: 4px 0 0 4px;
}

.square-white {
	background-color: #fff;
	color: #55575a;
	padding: 12px;
	border-radius: 4px 0 0 4px;
	border: 1px solid #54575a;
	border-right: 0;
	border-radius: 4px 0 0 4px;
}

.square-white-90 {
	background-color: #fff;
	color: #55575a;
	padding: 10px;
	border-radius: 4px 4px 0 0;
	border-bottom: 1px solid #cecccc;
	transform: rotateZ(-90deg);
	margin-right: 10px;
}

.square-white-0 {
	background-color: #fff;
	color: #55575a;
	padding: 10px;
	border-radius: 4px 0 0 4px;
	border-right: 1px solid #cecccc;
	margin-right: 10px;
}

.tab-action {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: 1.1rem;
	border: 1px solid #cecccc;
	border-radius: 4px;
	margin-bottom: 12px;
}

.red {
	color: rgb(206, 3, 3);
}

.angle3 {
	width: 6px;
	height: 14px;
	margin-top: 14px;
	margin-right: 10px;
	float: right;
}

.person-details-container {
	display: flex;
	align-items: center;
}

.person-details {
	width: 100%;
}

.person-details p {
	color: black;
	margin: 0;
}

.person-details__circles {
	display: flex;
	margin: 2px 0;
}

.person-bubble-name {
	background-color: #55575a;
	border-radius: 20px;
	color: #fff;
	margin-right: 15px;
	height: 40px;
	width: 40px;
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: .9rem;
	text-align: center;
	align-items: center;
	padding: 10px 5px 5px 5px;
}

.bubble-name {
	background-color: #55575a;
	border-radius: 20px;
	color: #fff;
	margin: 5px -20px 0 5px;
	height: 40px;
	width: 40px;
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: .9rem;
	text-align: center;
	align-items: center;
	padding: 10px 5px 5px 5px;
}

.bubble-name-trial {
	background-color: #55575a;
	border-radius: 20px;
	color: #fff;
	margin: 5px -20px 0 0px;
	height: 40px;
	width: 40px;
	font-family: 'HumanistSlabserif712BT';
	font-weight: 100;
	font-size: .9rem;
	text-align: center;
	align-items: center;
	padding: 10px 5px 5px 5px;
}

.bubble-name-lg {
	background-color: #55575a;
	border-radius: 40px;
	color: #fff;
	height: 80px;
	width: 80px;
	font-family: "EgyptienneURWLig", sans-serif;
	font-weight: 100;
	font-size: 2rem;
	text-align: center;
	line-height: 2.4;
	margin: 0 auto;
}

.myStats-title {
	font-family: "Futura", serif;
	font-weight: 600;
	font-size: 1.6rem;
	text-align: center;
}

.myStats-category {
	font-family: "Futura", serif;
	font-weight: 100;
	font-size: .8rem;
	text-align: center;
	color: #8c8b8b;
	line-height: 2.8;
}

.stats-myName {
	margin: 0 15px 0 15px;
}

.myStats-dots {
	text-align: center;
}

.dots-survey-dkGrey,
.dots-survey {
	float: right;
	font-size: 1.3rem;
	padding-top: 2px;
	color: #333333;
}

.dots-survey-myStats {
	float: right;
	font-size: 1.3rem;
	padding-top: 2px;
	margin-top: -50px;
}

.navbarfilename {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: .9rem;
	word-break: break-word;
}

.subtitle-tiny {
	color: #333;
	font-size: .6rem;
	font-weight: 100;
	justify-content: space-between;
	text-transform: uppercase;
}

.subtitle-tiny-grey {
	color: rgb(136, 136, 136);
	font-size: 10.5px;
	justify-content: space-between;
	text-transform: uppercase;
}

.subtitle-tiny-ltGrey {
	color: rgb(187, 187, 187);
	font-size: 10.5px;
	font-weight: 100;
	justify-content: space-between;
	text-transform: uppercase;
}

.lt-grey {
	color: rgb(152, 152, 152);
}

.subtitle-tiny img {
	margin-top: -15px;
}

.file-details2.user-survey {
	padding: 15px;
	margin-bottom: 15px;
	margin-top: 15px;
	width: 100%;
	line-height: 15px;
	border-top: 1px solid #333;
	border-bottom: 1px solid #cecccc;
}

.completed-sml {
	font-size: .5rem;
	display: block;
}

.collected-sml {
	font-size: .5rem;
}

.adjust-mt {
	margin-top: 15px;
}

.adjust-ml {
	margin-left: 25%;
}

.tab-rt-extend {
	background-color: #fff;
	border-radius: 4px 0 0 20px;
	margin: 35px -15px 35px 0;
	padding: 15px;
	box-shadow: 4px 10px 16px #ccc;
	background-size: contain;
}

.pr-adj {
	padding-right: -15px;
}

.dots-img {
	size: 90%;
	margin-top: -5px;
}

.user-survey {
	font-family: "Futura", serif;
	font-weight: 900;
	font-size: .9rem;
	padding: 10px 0 0 30px;
	display: inline-block;
}

.title-user {
	margin-top: 10px;
}

/*------------ FORM -------------*/

.text-block {
	border-top: 1px solid #838383;
	background-color: #fff;
	background-size: contain;
	margin-top: 35px;
	padding: 8px 0;
}

.text-block.transparent {
	background-color: transparent;
}

.text-block .question {
	font-family: 'HumanistSlabserif712BT-black';
	font-size: .8rem;
	margin-bottom: 10px;
}

.question.pie-line {
	border-bottom: 1px solid #e9e9e9;
	padding-bottom: 10px;
}

form label {
	font-family: "Futura", serif;
	font-weight: 100;
	font-size: .7rem;
}

.style-a {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	padding: 5px 0 4px;
}

.right {
	margin-left: 100%;
}

.bubble-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

input[type=radio] {
	width: 17px;
	height: 17px;
}

.survey-ans {
	padding: 9px 0 18px;
	align-items: top;
	border-top: 1px solid #d8d8d8;
}

.survey-ans-pie {
	padding: 9px 0 0px;
	align-items: top;
	border-top: 1px solid #d8d8d8;
}

.form-control.survey-textarea {
	border: 1px solid #fff !important;
	font-family: 'HumanistSlabserif712BT-Roman';
	font-size: 13px;
}

.form-survey {
	margin-bottom: 80px;
}

.btn-large {
	background-color: #f0b43f;
	border: none;
	padding: 12px 110px;
	font-size: 1rem;
	border-radius: 0;
	color: #333;
	margin: 0 auto;
}

.btn-large-edit {
	background-color: #f0b43f;
	border: none;
	padding: 12px 126px;
	font-size: 1rem;
	border-radius: 0;
	color: #333;
	margin: 0 auto;
	opacity: .5;
}

.btn-large-coral {
	background-color: #b83c27;
	color: white;
	border: none;
	padding: 12px 116px;
	font-size: 1rem;
	border-radius: 0;
	margin: 0 auto;
}

.preview {
	position: fixed;
	font-weight: 100;
	font-family: "EgyptienneURWLig", sans-serif;
	text-align: center;
	position: relative;
	padding: 15px 35px 15px 0;
	color: #fff;
	margin: auto;
}

.tab-navbar-preview {
	background-color: #545659;
	background-size: contain;
	display: flex;
	position: fixed;
	width: 100%;
	max-width: 900px;
	height: 70px;
	z-index: 25;
}

.close-preview {
	font-family: "Futura", serif;
	font-size: 28px;
	font-weight: 100;
	color: white;
	margin-top: 6px;
	padding-left: 15px;
}

/*---------- SURVEY RESULT -----------*/

.btn-survey-slim {
	font-size: 9px;
	width: 50%;
	text-align: center;
	padding: 7px 10px;
	letter-spacing: 1;
	font-family: "Futura Std Book", sans-serif;
}

.btn-survey-slim.active {
	background-color: #444;
	color: #fff;
}

.btn-survey-twin {
	border-radius: 4px;
	border: 1px solid #777;
	margin: 18px 5px;
	display: flex;
	flex-direction: row;
}

.result-stats {
	display: flex;
	font-weight: 800;
	font-size: .9rem;
	line-height: 1.1;
}

.stats-percent {
	float: left;
	padding-left: 0;
	color: #555;
}

.stats-no {
	color: #b1b1b1;
}

img.stats-line {
	height: 2px;
	z-index: 10;
	margin-left: 4px;
}

.pie {
	width: 100%;
	max-width: 500px;
	max-height: 300px;
}

.pie-info {
	text-align: center;
	margin-top: 5px;
	margin-bottom: 5px;
}

.no-of-stats {
	padding: 10px;
	width: 100%;
	border-top: 1px solid #e9e9e9;
	text-align: center;
	font-size: .7rem;
	color: #b1b1b1;
}

.btn-survey-slim.active {
	background-color: #444;
	color: #fff;
}

.btn-survey-twin {
	border-radius: 4px;
	border: 1px solid #777;
	margin: 18px 5px;
	display: flex;
	flex-direction: row;
}

.result-stats {
	display: flex;
	font-weight: 800;
	font-size: .9rem;
	line-height: 1.1;
}

.stats-percent {
	float: left;
	padding-left: 0;
	color: #555;
}

.stats-no {
	color: #b1b1b1;
}

.trial-name {
	font-family: 'HumanistSlabserif712BT';
	font-size: 14px;
	color: #000;
	border-bottom: 1px solid #d8d8d8;
	margin-bottom: 1rem;
}

.trial-name p {
	margin: 0;
}

.pie-info {
	text-align: center;
	margin-top: 5px;
	margin-bottom: 5px;
}

.no-of-stats {
	padding: 10px;
	width: 100%;
	border-top: 1px solid #e9e9e9;
	text-align: center;
	font-size: .7rem;
	color: #b1b1b1;
}

.stronger {
	font-family: "Futura", serif;
	font-weight: 900;
	color: #777;
}

.include {
	font-size: .7rem;
	font-family: "Futura", serif;
	font-weight: 100;
	display: inline-block;
}

.include i {
	margin-right: 8px;
	padding: 4px;
	border: 1px solid #777;
	border-radius: 20px;
}

.inclue-export {
	display: flex;
	padding: 4px;
	justify-content: space-between;
}

.include-export switch {
	float: right;
}

.trial-name {
	font-weight: 300;
	font-family: 'HumanistSlabserif712BT';
	font-size: 14px;
	/* letter-spacing: 2; */
	color: #000;
	border-bottom: 1px solid #d8d8d8;
}

.label-sml {
	font-family: "Futura Std Book", sans-serif;
	font-weight: 100;
	font-size: .5rem;
	color: #555;
}

/*--------- Extra ---------------*/

a.inactive ul.trials-progress {
	opacity: .5;
}

.react-pdf__Page__canvas {
	width: 100% !important;
	height: 100% !important;
}

/* profile css */

#profile.profile {
	height: 100%;
	margin: 0;
	width: 100%;
	max-width: 900px;
	margin: auto;
}

#profile.profile .modal-content {
	height: 100%;
}

.profile__display-information {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin-bottom: 20px;
	box-shadow: 0 1px 10px #dedede;
}

.profile__display-information-name {
	margin-top: 10px;
	font-family: 'FuturaStd Bold', sans-serif;
}

.profile__display-information-profile-image {
	cursor: none;
}

.profile__image-container {
	width: 5rem;
	cursor: pointer;
}

.profile__image {
	width: 100%;
	border-radius: 50%;
}

.profile__image--letters {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5rem;
	height: 5rem;
	background-color: #54575a;
	color: #fff;
	font-size: 1.5rem;
	font-family: 'FuturaStd Bold', sans-serif;
	font-weight: bold;
	cursor: pointer;
}

.profile__image--list {
	max-width: 50px;
	height: 50px;
	font-size: 1rem;
	margin-right: 10px;
}

.profile__image--small {
	width: 2.5rem;
	height: 2.5rem;
	font-size: 1rem;
}

.profile__image--medium {
	width: 3.5rem;
	height: 3.5rem;
	font-size: 1.2rem;
}

.profile__image--xtra-small {
	width: 1.5rem;
	height: 1.5rem;
	font-size: .5rem;
}

.profile__image-delete-button {
	height: 18px;
	width: 18px;
	position: absolute;
	top: 3px;
	right: 3px;
	padding: 0;
	background-color: transparent;
	border: none;
}

.profile__image-delete-button-image {
	display: block;
	width: 100%;
}

.profile-form {
	padding: 0 15px;
}

#form.profile-form .profile-form__input {
	border: none;
	border-bottom: 1px solid #e2e3e3;
	border-radius: 0;
	padding-left: 0;
	margin-right: 0;
	font-size: 0.75rem;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

#form.profile-form .profile-form__label {
	margin-bottom: .1rem;
	color: #5e6163;
	font-size: 0.7rem;
	font-family: 'FuturaStd Book', sans-serif;
}

#form.profile-form .profile-form__icon {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #e2e3e3;
	background-color: #fff;
	color: #b7b7b7;
}

#form.profile-form .form-control:disabled,
.form-control[readonly] {
	background-color: #fff;
	color: #b7b7b7;
}

.margin-5 {
	margin: 5px;
}

.flex-basis-0 {
	flex-basis: 0;
}

.flex-center {
	padding: 20px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.hidden {
	display: none !important;
}

.flex-center-vertical {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.circles {
	display: flex;
}

.margin-top-90 {
	margin-top: 90px;
}

.padding-top-90 {
	padding-top: 90px;
}

.button-open-modal {
	width: 25px;
	padding: 0;
	background-color: transparent;
	border: none;
}

.button-open-modal__image {
	width: 100%;
}

.dashboard-menu-container {
	height: 80% !important;
	border-bottom-right-radius: 15px;
}

.dashboard-menu {
	padding-top: 50px;
}

.dashboard-menu .dashboard-menu__title {
	color: #b1b4b3;
	font-family: "Futura Std Book", serif;
	font-size: 13px;
	letter-spacing: 1px;
}

.dashboard-menu__item {
	padding-bottom: 20px;
}

.dashboard-menu__item a {
	color: black;
}

.dashboard-menu .dashboard-menu__item-notification {
	color: #b1b4b3;
}

.dashboard-menu__line {
	width: 41%;
	border-top: 1px solid #b1b4b3;
}

.dashboard-item-name {
	font-family: "Futura Std Book", sans-serif;
	text-transform: uppercase;
	white-space: nowrap;
}

.crush-trial-progress__name {
	font-family: "Futura Std Bold", sans-serif;
}

.crush-trial-progress__information {
	font-family: "Futura Std Book", sans-serif;
}

.crush-trial-progress__information-remaining {
	color: #b1b4b3;
}

#form-survey .modal-content {
	height: 100vh;
	overflow-y: auto;
	background: #f1f1f1;
}

.surveys-and-evaluations__statistics {
	margin-bottom: 5px;
}

.surveys-and-evaluations__statistics-label {
	font-family: "Futura Std Book", sans-serif;
}

.surveys-and-evaluations__details-label {
	font-family: "Futura Std Book", sans-serif;
}

.surveys-and-evaluations__details-text {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.card-list__name {
	color: #000;
	font-family: "Futura Std Bold", sans-serif;
}

.card-list__type {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
}

.card-list__completed {
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
}

.product-education__description {
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.product-education__tags {
	color: #bbbbbb;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
}

.dashboard-container {
	min-height: 100vh;
}

.trial-statistics {
	display: flex;
	justify-content: space-between;
}

#remove-a-teammate-modal .modal-content {
	border-radius: 0;
}

.person__information {
	flex-direction: column;
}

.modal-content {
	color: #000;
}

.open-modal-button {
	float: right;
	height: 25px;
	padding: 0;
	width: 25px;
	background-color: transparent;
	border: none;
}

.open-modal-button__image {
	display: block;
	width: 100%;
}

#state {
	padding: 1px 2px;
}

#zip-code {
	width: 100%;
}

.circle-label {
	transform: translateY(0.25em);
}

.circle-percentage {
	font-size: 0.6em;
	line-height: 1;
	text-anchor: middle;
	transform: translateY(-0.25em);
}

.circle-text {
	font-size: 0.2em;
	text-transform: uppercase;
	text-anchor: middle;
	transform: translateY(0.7em);
}

figure {
	margin: 0 !important;
}

.trial-progress-item-container {
	display: flex;
	align-items: center;
	color: black;
}

.circle-inidicator-container {
	width: 40px;
}

svg text {
	font-family: LineAwesome;
}

.specialty_selected {
	display: none;
}

.specialty_select_list_item.selected .specialty_selected {
	display: block;
}

.specialty_select_list_item.selected .specialty_not_selected {
	display: none;
}

.customer_select_list_item .competitor_img {
	-webkit-filter: contrast(0);
	filter: contrast(0);
}

.customer_select_list_item.selected .competitor_img {
	-webkit-filter: unset;
	filter: unset;
}

.max-width {
	max-width: 832px;
	margin: 0 auto;
}

.sykButton {
	border: none;
	padding: 5px 20px;
	background-color: #ffb500;
	color: #000;
	text-transform: uppercase;
	font-family: "Futura Std Book", sans-serif;
}

.sykButtonCancel {
	border: none;
	padding: 5px 20px;
	background-color: #b1b4b3;
	color: #000;
	text-transform: uppercase;
	font-family: "Futura Std Book", sans-serif;
}

.sykButtonOutline {
	border: none;
	padding: 5px 20px;
	border: 1px solid black;
	background: white;
	color: #000;
	text-transform: uppercase;
	font-family: "Futura Std Book", sans-serif;
}

.trial-statistics-container {
	display: flex;
	justify-content: space-between;
	margin: 0 5px;
}

.engagement-email-verbatim-container {
	margin: 0 0 80px 0;
}

.survey-or-evaluation-statistics {
	margin: 0 30px 0 0;
}

.chart {
	border: 1px solid #b1b4b3;
	padding: 35px 35px 60px 35px;
	margin-bottom: 75px;
	position: relative;
	border-radius: 20px;
}

.chart__icon {
	position: absolute;
	top: 50%;
	left: -20px;
	transform: translate(0%, -50%);
}

.chart__graph-container {
	text-align: center;
}

.chart__title {
	border-bottom: 1px solid #b1b4b3;
	padding: 0 0 15px 0;
	margin: 20px 0 10px 0;
	font-size: 15px;
	font-family: "Futura Std Bold", serif;
	line-height: 1;
}

.chart__statistics-percentage {
	width: 86px;
	color: #e5e5e5;
	font-family: "Futura Std Bold", serif;
	font-size: 21px;
	display: inline-block;
	text-align: right;
}

.chart__statistics-text {
	display: inline-block;
	padding: 0 0 0 15px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
	color: #000;
}

.chart__buttons-container {
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: -25px;
	left: 0;
	width: 100%;
}

.react-toggle {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
	background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #128d15;
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099e0;
	-moz-box-shadow: 0px 0px 3px 2px #0099e0;
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099e0;
	-moz-box-shadow: 0px 0px 5px 5px #0099e0;
	box-shadow: 0px 0px 5px 5px #0099e0;
}

.toggle-yellow.react-toggle--focus .react-toggle-thumb,
.toggle-yellow.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	box-shadow: none;
}

.toggle-yellow.react-toggle--checked .react-toggle-track,
.toggle-yellow.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #ffb500;
}

.toggle-yellow .react-toggle-track,
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #e5e5e5;
}

.toggle-yellow .react-toggle-thumb {
	background-color: #fff;
	border: none;
}

.chart__header-title {
	border-bottom: 1px solid #b1b4b3;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	font-size: 15px;
	font-family: "Futura Std Bold", serif;
	line-height: 1;
}

.left-arrow-icon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}

.left-arrow-icon-button {
	border: none;
	padding: 0;
	background-color: transparent;
	width: 100%;
}

.signature__actions {
	position: absolute;
	bottom: 0px;
	width: 90%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0px 5%;
	padding: 20px 0;
	border-top: 1px solid black;
}

.signature__actions button {
	min-width: 40%;
	height: 40px;
}

.signature__details {
	position: absolute;
	width: 90%;
	margin: 0 5%;
	padding: 20px 0;
	display: block;
	z-index: 50;
}

.signatureModal .modal-content {
	border-radius: 0;
}

.signatureModal .thankyoumodal {
	background: white;
	height: 100vh;
}

.signatureModal .thankyou {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 15px;
}

.signatureModal .thankyou h1 {
	font-family: 'Futura Std Bold';
}

.signatureModal .thankyou button {
	height: 50px;
	background: white;
	border: 1px solid #ffb500;
	width: 150px;
}

.goldGradientBar {
	width: 100%;
	height: 15px;
	background: linear-gradient(-90deg, #ffb400, #b47218);
}

.returnDevice {
	padding: 15px;
}

.signatureModal .closeModal {
	position: absolute;
	right: 0;
}

.form_error_msg {
	color: #c60000;
	margin-top: 1rem;
}

.trial-status__archived {
	margin-bottom: .25rem;
	background: #b1b4b3;
	color: #000;
	cursor: pointer;
	text-transform: uppercase;
}

.customize-email__preview-wrapper {
	margin: 0 0 25px 0;
	padding: 20px 0 0 0;
	box-shadow: 0 0 9px rgb(0 0 0 / 25%);
}

.preview-email-container {
	max-width: 650px;
	margin: 0 auto;
	background-color: #ffffff;
}

.customize-email__content-textarea-container {
	margin: 15px 15px 15px 15px;
}

.customize-email__content-textarea {
	margin: 0 0 8px 0;
	padding: 15px 25px 15px 25px;
	width: 100%;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 16px;
}

.input-checkmark-circle {
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
}

.input-checkmark-circle--small {
	height: 15px;
	width: 15px;
	font-size: 11px;
}

.hidden-radio-input {
	display: none;
}

.hidden-radio-input[type=radio]:checked + .input-checkmark-circle,
.hidden-radio-input[type=radio]:disabled + .input-checkmark-circle,
.hidden-checkbox-input[type=checkbox]:checked + .input-checkmark-circle {
	background-color: #ffb500;
}

.cancel-and-submit-buttons-container {
	display: flex;
	justify-content: space-between;
	margin: 35px 0 0 0;
}

.modal-buttons {
	padding: 32px 30px 30px 30px;
}

.modal-buttons__title {
	border-bottom: 1px solid #abaead;
	margin: 0 0 30px 0;
	width: 100%;
	font-family: "EgyptienneURWLig", sans-serif;
	font-size: 31px;
}

.modal-buttons__text {
	margin: 0;
	font-family: 'HumanistSlabserif712 BT Black', sans-serif;
	font-size: 14px;
	line-height: 20px;
}

.modal-buttons__subtext {
	margin: 0;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
	line-height: 20px;
}

.talk-track-single-title {
	font-family: "Futura Std Bold", sans-serif;
	font-size: 27px;
}

.person-information-header {
	position: relative;
}

.customer-information-header {
	position: relative;
	padding: 90px 0 0 0;
}

.person-information-header-menu-container {
	position: absolute;
	top: 20px;
	right: 10px;
	text-align: center;
}

.person-information-header__menu-button-icon {
	border: none;
	padding: 0;
	background-color: transparent;
}

.person-information-header__menu-icon {
	font-size: 25px;
}

.person-information-header-menu {
	padding: 5px 20px;
	position: absolute;
	right: 0;
	z-index: 1;
	width: 120px;
	background-color: #ffffff;
	-webkit-box-shadow: 1px 1px 15px 4px rgb(0 0 0 / 10%);
	box-shadow: 1px 1px 15px 4px rgb(0 0 0 / 10%);
}

.person-information-header-menu__text,
.customer-information-header-menu__text {
	width: 100%;
	border: none;
	padding: 0;
	background-color: transparent;
	color: #4b4b4b;
	font-family: 'Futura Std Book', sans-serif;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 40px;
	text-transform: uppercase;
}

.person-information-header-menu__text:last-child {
	border-top: 1px solid #b1b4b3;
}

.edit-trial-form {
	padding: 10px 0;
}

.edit-trial-form__inputs-container {
	display: flex;
	flex-wrap: wrap;
}

.edit-trial-form__group {
	display: flex;
	flex-direction: column;
	padding: 0 15px 0 15px;
	margin: 0 0 20px 0;
	width: 50%;
}

.edit-trial-form__label {
	margin: 0 0 5px 0;
	font-size: 10px;
	font-family: 'Futura Std Book', sans-serif;
	line-height: 16px;
	text-transform: uppercase;
}

.edit-trial-form__input {
	border: none;
	border-bottom: 1px solid #54575a;
	position: relative;
	z-index: 2;
	width: 100%;
	background-color: transparent;
	color: #54575a;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
	line-height: 22px;
}

.edit-trial-form__influence {
	margin: 0 0 20px 0;
	padding: 0 0 0 15px;
	width: 30%;
}

.edit-trial-form__button-container {
	text-align: center;
}

.edit-trial-details-form {
	margin: 0 -15px;
}

.yellow-button {
	border: 1px solid #ffb500;
	padding: 7px 25px 6px;
	background-color: transparent;
	border-radius: 15px;
	color: #ffb500;
	font-family: 'Futura Std Book', sans-serif;
	font-size: 12px;
	letter-spacing: 0.55px;
	line-height: 12px;
	text-transform: uppercase;
}

.audio-list__music-icon-container {
	margin: 0 20px 0 0;
	padding: 10px 13px 10px 13px;
	background-color: #b1b4b3;
	border-radius: 10px;
	color: #ffffff;
	font-size: 20px;
}

.audio-list__download-and-downloaded-button-icon {
	border: none;
	padding: 0;
	background-color: transparent;
}

.audio-list__download-icon {
	display: block;
	color: #54575a;
	font-size: 20px;
}

.audio-list__downloaded-icon {
	display: block;
	color: #ffb500;
	font-size: 20px;
}

.audio-player__close-button {
	align-self: center;
	border: none;
	margin: 10px 0;
	padding: 0;
	background-color: transparent;
}

.audio-player__close-button-image {
	width: 45px;
}

.audio-player__audio-information {
	display: flex;
	align-items: center;
	padding: 15px;
}

.audio-player__text {
	padding: 0 15px;
	font-family: 'HumanistSlabserif712 BT Roman', sans-serif;
	font-size: 14px;
	line-height: 1.3;
}

.audio-player-modal {
	margin: 0 auto !important;
	position: fixed !important;
	left: 0;
	right: 0;
	bottom: 0;
	height: 95%;
	width: 100% !important;
}

.audio-player-modal .modal-content {
	height: 100%;
	overflow-y: auto;
}

.audio-player__play-and-pause-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: #54575a;
	border-radius: 50%;
}

.audio-player__play-and-pause-icon {
	color: #ffffff;
	font-size: 30px;
}

.audio-player__rewind-icon,
.audio-player__forward-icon {
	color: #54575a;
}

.rhap_play-pause-button {
	margin: 0 30px !important;
}

.rhap_progress-filled,
.rhap_progress-indicator {
	background-color: #ffb500 !important;
}

.rhap_progress-bar {
	background-color: #b1b4b3 !important;
}

.rhap_time {
	color: #b1b4b3 !important;
	font-family: "Futura Std Book", sans-serif;
}

.list__item-dark-gray {
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	letter-spacing: .4px;
}

#procedure-details__date-picker .react-datepicker-wrapper {
	display: block;
}

.list__text-inline-image {
	margin: 0 2px;
	width: 12px;
}

.list__item-information {
	border: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0;
	background-color: transparent;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 0.65rem;
	text-transform: uppercase;
}

.input__icon {
	position: absolute;
	bottom: 2px;
	right: 0;
	z-index: 1;
}

.input__icon--position {
	bottom: 6px;
	right: 6px;
}

.date-inputs-container .react-datepicker-popper {
	z-index: 3;
}

.edit-account-info-button-container {
	text-align: center;
}

.edit-account-info-button {
	border: 1px solid #54575a;
	padding: 7px 25px 6px;
	background-color: transparent;
	border-radius: 15px;
	color: #54575a;
	font-family: "Futura Std Book", sans-serif;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.55px;
	text-transform: uppercase;
}

.list-details__icon {
	color: #000000;
}

.right-min {
	float: right;
	width: min-content;
	pointer-events: all;
}

.install-modal .modal-content {
	padding: 30px;
}

.install-modal-title {
	margin: 0 0 30px 0;
	color: #000000;
	font-family: "Futura Std Bold", sans-serif;
	font-size: 30px;
	text-align: center;
}

.install-modal-text {
	margin: 0 0 30px 0;
	color: #000000;
	font-family: "Futura Std Book", sans-serif;
}

.install-modal-done-text {
	font-family: "Futura Std Bold", sans-serif;
}

.light-text {
	color: #b1b4b3;
}

.full-width-form-element .react-datepicker-wrapper,
.full-width-form-element .react-datepicker__input-container {
	display: block;
}

.modal-button {
	border: none;
	height: 25px;
	width: 25px;
	padding: 0;
	background-color: transparent;
}

.modal-button--large {
	height: 43px;
	width: 43px;
}

.modal-button-image {
	width: 100%;
}

.form-add-surgeon__not-in-trial-text {
	color: #b1b4b3;
	font-family: "Futura Std Book", sans-serif;
}

@media screen and (max-width: 768px) {
	.edit-trial-form__group {
		width: 100%;
	}
}

.chartContainer {
	position: relative;
	max-width: 400px;
	margin: auto;
}

.bestLabel {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -70px;
	margin-top: -50px;
	font-family: 'FuturaStd-Bold';
	font-size: 50px;
	line-height: 53px;
	text-transform: uppercase;
	font-weight: 900;
	color: #ffb500;
	text-shadow: 0px 0px 9px rgb(0 0 0 / 35%);
}

.adminLogin {
	display: flex;
	align-items: center;
	height: 175px;
	justify-content: space-between;
	flex-direction: column;
	margin-top: 50px;
	padding: 25px;
	box-shadow: 0 0 10px lightgray;
}
.adminLogin input {
	border: 1px solid #b1b4b3;
}
.adminLogin button {
	background: #ffb500;
	border: none;
	padding: 5px 20px;
}

.outlineButton{
	background: #FFFFFF;
	border-radius: 35px;
	border: 1px solid #54575A;
	font-family:'Futura Std Book';
	font-size: 13px;
	height: 40px;
	letter-spacing: 1.5px;
	white-space: nowrap;
	width: 150px;
}

.buttonDisabled{

	opacity: .3;
}

.closed{

	color: #FFFFFF;
	background: #54575A;
	border: unset !important;

}

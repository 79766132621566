.checklists {
	padding-top: 90px;
	background-color: white;
}

.checklist__title {
	margin: 0 15px;
	font-family: "EgyptienneURWLig", sans-serif;
}

.checklist-container {
	border-top: 1px solid #b1b1b1;
	padding: 0 10px;
}

.checklist {
	display: flex;
	align-items: center;
	padding: 0;
	background-color: #fff;
	color: #000;
	width: 100%;
	border-bottom: 1px solid #e7e7e9;
}

.checklist a {
	color: #000;
}

.checklist__wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 50px;
	color: black;
}

.checklist__details {
	display: block;
	width: 100%;
}

.checklist__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.checklist_category-title {
	margin: 0;
	font-size: 1.2rem;
	font-family: "Futura Std Bold", sans-serif;
}

.checklist__item-title {
	margin-bottom: 0;
	margin-right: 5px;
	font-family: "HumanistSlabserif712 BT Roman", sans-serif;
	padding: 5px 0;

}

.checklist__text {
	margin: 0;
	color: #b1b4b3;
	font-size: 0.7rem;
	font-family: "HumanistSlabserif712 BT Roman", sans-serif;
	letter-spacing: -0.3px;
}

.circle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 1px solid #c8c7cc;
	margin-right: 10px;
	margin-bottom: auto;
	margin-top: 1rem;
}

.circle__completed {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 10px;
	background: #ffb500;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	margin-bottom: auto;
	font-size: 14px;
	-webkit-text-stroke-width: medium;
}

.checklist__title-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	background: white;
	border-bottom: 1px solid #b1b1b1;
}

.checklist__add-button {
	border: none;
	padding: 0;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffb500;
	color: white;
	box-shadow: 3px 3px 5px #00000030;
	border-radius: 50%;
	margin: 0 10px;
	font-size: 24px;
	-webkit-text-stroke-width: medium;
}

.checklist__search-container {
	display: flex;
	align-items: center;
	widows: 100%;
	height: 60px;
	background: white;
	padding: 0 10px;
}

.checklist__search-container i {
	font-size: 25px;
	color: gray;
	transform: rotate(-90deg);
}

.checklist__search-input {
	border: none;
	width: 100%;
	margin-left: 10px;
}

.checklist__category-title-container {
	display: flex;
	margin-top: 2rem;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #b1b1b1;
	padding-bottom: 5px;
}

.checklist__category-title-container i {
	font-size: 20px;
}

.checklist__indicators {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.checklist__comments {
	text-align: right;
	color: #b1b4b3;
	font-size: 0.7rem;
	font-family: "HumanistSlabserif712 BT Roman", sans-serif;
	letter-spacing: -0.3px;
	white-space: nowrap;
	margin-right: 5px;
}

.checklist__indicators p {
	text-align: right;
	color: #b1b4b3;
	font-size: 0.7rem;
	font-family: "HumanistSlabserif712 BT Roman", sans-serif;
	letter-spacing: -0.3px;
	white-space: nowrap;
	margin: 0;
}

.checklist__due-date-input {
	width: 100px;
	height: 26px;
	font-size: .8rem;
	border: 1px solid lightgray;
	text-align: center;
	font-family: 'HumanistSlabserif712 BT Roman';
}

.checklist__due-date-input::placeholder {
	font-size: .7rem;
	
}

.checklist__add-new-item-container {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checklist__add-new-item {
	background: white;
	border-radius: 15px;
	border: 1px solid #54575A;
	color: #54575A;
	font-size: 13px;
	letter-spacing: 1.5px;
	padding: 5px 50px;
	white-space: nowrap;
	width: fit-content;
}

.checklist__popover-action {
	text-align: center;
	margin: 5px;
}

.checklist__popover-action-red {
	text-align: center;
	margin: 5px;
	color: red;
}